import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { BackgroundService } from './background.service';

import { faAngleDown, faAngleRight, faAt, faClipboardList, faDesktop, faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { tap } from 'rxjs/operators';

declare var gtag: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    @ViewChild('scrollToBottom') private scrollContainer: ElementRef;

    constructor(private backgroundService: BackgroundService) {}
    sourceCode = '';
    faAngleDown = faAngleDown;
    faAngleRight = faAngleRight;
    faAt = faAt;
    faClipboardList = faClipboardList;
    faDesktop = faDesktop;
    faNewspaper = faNewspaper;

    ngOnInit() {
        this.backgroundService.fetch()
            .pipe(
                tap(text => {
                    this.sourceCode = text;
                    this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
                })
            )
            .subscribe()
    }
    gtagReportConversion(url) {
        var callback = function () {
            if (typeof(url) != 'undefined') {
                window.location = url;
            }
        };
        gtag('event', 'conversion', {
            'send_to': 'AW-771145593/krIBCL6-uZIBEPn-2u8C',
            'event_callback': callback
        });
        return false;
    }
    register() {
        console.log("Redirecting to chpro.youcanbook.me.");
        this.gtagReportConversion("https://chpro.youcanbook.me/");
    }
}
