import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import 'prismjs';
import 'prismjs/components/prism-python';
import { from  } from 'rxjs';
import { concatAll, delay, flatMap, map, repeat } from 'rxjs/operators';

declare var Prism: any;


@Injectable({
    providedIn: 'root'
})
export class BackgroundService {

    constructor(private http: HttpClient) {}

    fetch() {
        var tokens = []
        return this.http.get('assets/background.py', {responseType: 'text'})
            .pipe(
                repeat(100),
                flatMap(data => {
                    // Convert the text into an array of tokens.
                    let arr = Prism.tokenize(data, Prism.languages.python)
                    return from(arr)
                }),
                map(token => {
                    // Introduce 10ms delay between tokens.
                    return from([token]).pipe(delay(10))
                }),
                concatAll(),
                map(token => {
                    tokens.push(token)

                    // Decrease memory footprint: remove old tokens (out of view).
                    if (tokens.length > 500) {
                        tokens.shift();
                    }

                    // Update source code and scroll to bottom of pane.
                    return Prism.Token.stringify(Prism.util.encode(tokens), 'python');
                })
            )
    }
}
